import React, { useEffect, useState } from "react"
import Layout from "../components/layout";
import Title from "../components/title";
import Promo from "../components/promo";

const Calculator = ({ location }) => {

  const [rate, changeRate] = useState(200);
  const [hourly, changeHourly] = useState(50);
  const [target, changeTarget] = useState(3000);
  const [days, changeDays] = useState(15);
  const [difference, changeDifference] = useState(10);


  useEffect(() => {

    if(target > 0) {
      changeRate( (target * 1.3) / days )
      changeHourly( ( (target * 1.3) / days ) / 8 )
    }

  },[target]);

  return (

    <Layout
      path={location.pathname}>

      <Title
        title="Calculate your ideal day rate."
        width="max-w-sm">
        <p className="max-w-lg leading-relaxed mx-auto my-8 text-center text-lg md:text-xl">
          Knowing what to charge as a freelancer is always tricky, but it doesn't need to be. We'll help you
          figure out your ideal day rate so you know if freelancing is something that's going to work for you.
          Tell us what salary you'd like to take each month.</p>
      </Title>

      <div className="container max-w-lg mx-auto">

        <div className="flex flex-col space-y-8">

          <div className="rounded-md border border-gray-300 bg-gray-100">
            <div className="space-y-4">
              <div className="flex">
                <span className="inline-flex items-center pl-6 rounded-tl-md border-b border-gray-300 bg-white text-gray-500">
                $
                </span>
                <input
                  className="flex-1 block w-full rounded-none border-r-0" placeholder="50000"
                  type="input"
                  value={target}
                  onChange={(e) => changeTarget(e.target.value)} />
                <span className="inline-flex items-center px-6 bg-white rounded-tr-md border-b border-gray-300 text-gray-500">
                salary per month
                </span>
              </div>
            </div>
            <div className="flex items-center p-8">
              <div className="p-8 text-center border-r border-gray-200 w-1/2">
                <h3>Day Rate</h3>
                <h4 className="font-medium text-4xl">${Math.round(rate)}</h4>
              </div>
              <div className="p-8 text-center w-1/2">
                <h3>Hourly Rate</h3>
                <h4 className="font-medium text-4xl">${Math.round(hourly)}</h4>
              </div>
            </div>
          </div>

          <h2 className="text-xl text-lg md:text-xl leading-tight">The money you earn isn't all yours</h2>

          <p className="text-dark">
            This might sound obvious, but it's critical to know the difference between gross and net. The money you earn (gross) has to cover things like expenses, tax, etc.
            This leaves you with whatever is left (net) to pay yourself a salary.
          </p>

          <h2 className="text-xl text-lg md:text-xl leading-tight">Assumptions we've made</h2>

          <ul className="list-disc list-inside text-dark">
            <li>You'll work about 15 days a month on average</li>
            <li>Your average work day is 8 hours</li>
            <li>You'll take at least 20 days holiday a year</li>
            <li>You'll set aside 30% for things like expenses, tax, etc</li>
          </ul>

        </div>

      </div>

    </Layout>
)}

export default Calculator;